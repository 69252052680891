import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner"; // Import the spinner component

const plansData = [
  {
    name: "Basic Plan",
    duration: "1 Month",
    price: "₹2100",
    feature: [
      "Personalized dietician plan tailored to your goals and dietary preferences.",
      "Access to our mobile app for convenient meal tracking and progress monitoring.",
      "Email support to address your questions and concerns.",
      "Regular check-ins with a dedicated Dietician Priyanka to review your progress and provide guidance.",
    ],
  },
  {
    name: "Premium Plan",
    duration: "2 Months",
    price: "₹3500",
    feature: [
      "All the features included in the Basic Plan.",
      "One-on-one video consultations with your dedicated Dietician Priyanka for more personalized guidance and support.",
      "Recipe recommendations and meal planning assistance.",
      "Priority email support for quicker responses to your inquiries.",
      "Educational resources and guides to deepen your understanding of dietician and healthy habits.",
    ],
  },
  {
    name: "Ultimate Plan",
    duration: "3 Months",
    price: "₹5000",
    feature: [
      "All the features included in the Premium Plan.",
      "Unlimited access to video consultations with your dedicated Dietician Priyanka for ongoing support and accountability.",
      "Advanced progress tracking tools to monitor your weight, body measurements, and fitness goals.",
      "Customized meal plans and recipe suggestions based on your preferences and nutritional needs.",
      "Priority email and phone support for immediate assistance.",
    ],
  },
];

const Pricing = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    plan: "",
    duration: "",
    price: "",
  });
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading spinner

    try {
      await axios.post(
        "https://dt-priyanka-1.onrender.com/api/plans",
        formData
      );
      toast.success("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        plan: "",
        duration: "",
        price: "",
      });
      setShowModal(false);
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
      console.error("Error submitting form", error);
    } finally {
      setIsLoading(false); // End loading spinner
    }
  };

  return (
    <section className="text-gray-600 body-font">
      <ToastContainer />
      <div className="container px-5 py-4 mx-auto mt-10">
        <div>
          <h1 className="text-md opacity-80 text-center text-gray-900 mb-6">
            Choose Your Plan
          </h1>
          <div className="flex flex-wrap -m-4">
            {plansData.map((item) => (
              <div className="p-4 xl:w-1/3 md:w-1/2 w-full" key={item.name}>
                <div className="bg-[#fefcfc] p-6 rounded-lg flex flex-col relative overflow-hidden">
                  <h2 className="text-lg title-font mb-1 font-semibold">
                    {item.name}
                  </h2>
                  <h2 className="text-sm mb-2">{item.duration}</h2>
                  <div className="bg-blue-80 border-blue-200 border-[1px] px-2 py-3 rounded-lg mb-4">
                    {item.feature.map((f, index) => (
                      <div
                        key={index}
                        className={`mb-5 py-1 ${
                          index !== item.feature.length - 1
                            ? "border-b-[1px] border-blue-200"
                            : ""
                        }`}
                      >
                        <p className="flex items-center text-gray-600">{f}</p>
                      </div>
                    ))}
                  </div>
                  {item.name === "Ultimate Plan" ? (
                    <div className="bg-[#0A7CEA] px-2 py-3 rounded-lg mb-4">
                      <p className="flex items-center text-white mb-5">
                        The Ultimate Plan is designed for individuals who are
                        committed to achieving significant results and require
                        the highest level of support and personalization.
                      </p>
                    </div>
                  ) : null}
                  <h1 className="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4">
                    <span>{item.price}</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">
                      /mo
                    </span>
                  </h1>
                  <button
                    onClick={() => {
                      setShowModal(true);
                      setFormData({
                        ...formData,
                        plan: item.name,
                        duration: item.duration,
                        price: item.price,
                      });
                    }}
                    className="mt-auto text-bold text-white py-2 px-4 w-full focus:outline-none bg-[#0A7CEA] hover:bg-[#0D4372] rounded text-center"
                  >
                    Choose Plan
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="mt-28 fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-20">
          <div className="bg-white p-6 rounded-lg relative">
            {isLoading && (
              <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-10">
                <ThreeDots
                  height="50"
                  width="50"
                  color="#0A7CEA"
                  ariaLabel="loading"
                />
              </div>
            )}
            <h2 className="text-xl mb-4">Fill Your Details</h2>
            <p>Fill the form we will contact you shortly.</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full border-2"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full border-2"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-700">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="mt-1 block w-full border-2"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-700">Plan</label>
                <input
                  type="text"
                  name="plan"
                  value={formData.plan}
                  onChange={handleChange}
                  className="mt-1 block w-full border-2"
                  readOnly
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-700">Duration</label>
                <input
                  type="text"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  className="mt-1 block w-full border-2"
                  readOnly
                />
              </div>
              <div className="mb-2">
                <label className="block text-gray-700">Price</label>
                <input
                  type="text"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  className="mt-1 block w-full border-2"
                  readOnly
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="mr-4 py-2 px-4 bg-gray-500 text-white rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default Pricing;
