import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="bg-[#0F4B7C] w-full">
        <h1 className=" justify-center align-center text-[#ffffff] md:flex text-xl p-4 border-y border-gray-200">
          <span className="font-bold">Enduring Health</span>: Simple Nutrition,
          Lasting Health Forever.
        </h1>
      </div>
      <div className="pt-8 mx-auto w-full bg-gray-160 text-dark font-semibold">
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-7 px-6">
          <div className="sm:col-span-2">
            <div className="">
              <Link className="hover:text-gray-700" to="/">
                <img
                  className="block w-46 h-16 cursor-pointer"
                  src="Logobg.png"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm">
                Fitness starts with what you eat. Take control of your goals.
                Track calories, break down ingredients, and log activities.
              </p>
            </div>
          </div>
          <div className="space-y-4 text-sm sm:col-span-2 md:ml-8 gap-4 leading-25">
            <p className="text-2xl font-bold tracking-wide  text-[#0F4B7C]">
              Useful Links:
            </p>
            <nav className="flex flex-col gap-1  mb-10 list-none">
              <li className="mb-2">
                <a
                  href="https://www.google.com/search?q=dietitian+priyanka+singh+&client=ms-unknown&sca_esv=604fe53e2192f73a&sca_upv=1&sxsrf=ADLYWIL73TbJud_oWWZuJvzLxRawZZc2OQ%3A1723705089843&ei=Aae9Zrn8MqbiseMPp6vBoAQ&ved=0ahUKEwj5gM_stfaHAxUmcWwGHadVEEQQ4dUDCBA&uact=5&oq=dietitian+priyanka+singh+&gs_lp=Egxnd3Mtd2l6LXNlcnAiGWRpZXRpdGlhbiBwcml5YW5rYSBzaW5naCAyBhAAGBYYHjILEAAYgAQYhgMYigUyCBAAGKIEGIkFMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiABBiiBEiAP1CsIFjAN3ABeACQAQCYAe0BoAH-BKoBBTAuMS4yuAEDyAEA-AEB-AECmAIEoAKaBc#lrd=0x390d0370aeda6203:0x68c9c86c3d79309a,3,,,,"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" hover:text-gray-700"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Add Reviews
                </a>
              </li>
              <li className="mb-2">
                <Link
                  to="/support"
                  className=" hover:text-gray-700"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Support
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/privacypolicy"
                  className=" hover:text-gray-700"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/cancellationrefundpolicy"
                  className=" hover:text-gray-700"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Cancellation & Refund Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/termsofservice"
                  className=" hover:text-gray-700"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Terms of Service
                </Link>
              </li>
            </nav>
          </div>
          <div className="space-y-2 text-sm sm:col-span-2">
            <p className="text-2xl font-bold tracking-wide text-[#0F4B7C]">
              Contacts:
            </p>
            <div className="flex">
              <p className="mr-1 font-semibold text-[#0F4B7C] text-lg">
                Phone:
              </p>
              <a
                href="tel:8130548921"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 mt-1"
              >
                8130548921
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 font-semibold text-[#0F4B7C] text-lg">
                Email:
              </p>
              <a
                href="mailto:priyankadlm@gmail.com"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 mt-1"
              >
                priyankadlm@gmail.com
              </a>
            </div>
            <div className="flex">
              <p className="mr-1 font-semibold text-[#0F4B7C] text-lg ">
                Clinic:
              </p>
              <p className="mt-1">
                Mon to Fri 9:30am - 6:00pm; <br /> Sat 9:30am - 2:00pm
              </p>
            </div>
            <div className="flex">
              <p className="mr-1 font-semibold text-[#0F4B7C] text-lg">
                Address:
              </p>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800 mt-1"
              >
                E18, opposite Bikanerwala, Block E, Naraina Vihar, Naraina, New
                Delhi, Delhi 110028
              </a>
            </div>
          </div>
          <div>
            <span className="text-xl font-bold tracking-wide text-[#0F4B7C]">
              Follow on:
            </span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="/"
                className="text-[#0F4B7C] transition-colors duration-300 hover:text-deep-purple-accent-400 hover:scale-110 "
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/dietician_priyanka_singh/?utm_source=qr&igsh=MW1xOWU4NjdqaHlncA%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Connect with us on Instagram"
                className="text-[#DE2F58] transition-colors duration-300 hover:text-deep-purple-accent-400 hover:scale-110"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/people/Dietician-Priyanka-Singh/61564625685098/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Connect with us on Facebook"
                className="text-[#3B5999] transition-colors duration-300 hover:text-deep-purple-accent-400 hover:scale-110"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
              <a
                href="/"
                className="text-[#030303] transition-colors duration-300 hover:text-deep-purple-accent-400 hover:scale-110"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  className="h-5 w-5"
                >
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </a>
            </div>
            <p className="mt-4 text-sm text-gray-900">
              Get connected with us on social networks.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse px-6 justify-between pt-4 pb-4 border-t lg:flex-row bg-[#0F4B7C]">
          <p className="text-sm text-white">
            © Copyright 2024. All rights reserved.
          </p>
          <a
            href="tel:7970693331"
            className="text-sm text-white md:text-base mx-4 hover:text-orange-600"
          >
            {" "}
            Design by Tanmay & Team
          </a>
        </div>
        {/* <a
          href="https://wa.me/8130548921" // Replace with your WhatsApp number
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-16 right-4 bg-[#25D366] p-3 rounded-full shadow-lg transition-transform transform hover:scale-110"
          aria-label="Chat with us on WhatsApp"
        >
          <img
            src="/whatsapp.svg" // Path to your SVG image in the public folder
            alt="WhatsApp"
            className="h-8 w-8" // Adjust size as needed
          />
        </a> */}
        <a
          href="https://wa.me/8130548921?text=Thank%20you%20for%20contacting%20Dietician%20Priyanka%20Singh%21%20Our%20representative%20will%20get%20in%20touch%20with%20you%20shortly.%20To%20know%20more%20about%20us%2C%20kindly%20have%20a%20look%20at%20our%20website%20www.dieticianpriyanka.com"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-16 right-4 bg-[#25D366] p-3 rounded-full shadow-lg transition-transform transform hover:scale-110"
          aria-label="Chat with us on WhatsApp"
        >
          <img
            src="/whatsapp.svg" // Path to your SVG image in the public folder
            alt="WhatsApp"
            className="h-8 w-8" // Adjust size as needed
          />
        </a>

        <a
          href="#top"
          className="fixed bottom-2 right-4 bg-[#0A7CEA] p-3 rounded-full shadow-lg transition-transform transform hover:scale-110"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.65em"
            height="1.65em"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M12 20V4m0 0l6 6m-6-6l-6 6"
            />
          </svg>
        </a>
      </div>
    </>
  );
};
export default Footer;
